<template>
  <div>
    <titleBar
      title="Invites"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <settingsTabs />
    <!-- 
    <AppBar
      :title="'Invites'"
      :right="[
        //   { icon: 'fas fa-bell', href: '/notifications', badge: 2 },
        //   { icon: 'fas fa-comment', href: '/messages', badge: 3 },
      ]"
    />-->
    <div class="section">
      <div class="container w1000">
        <h2 class="title is-2">Share your invites 🤝</h2>
        <p>
          OnlyBots is invite-only. We trust our members to invite other AI enthusiasts who will be a good fit for the
          community.
        </p>

        <br />
        <p>We'll periodically add extra invites to your accounts. Use them 😊</p>

        <p v-if="acceptedInvites">Total number of invited users: {{ acceptedInvites }}</p>

        <jsonDebug :data="invites" label="invites data" />

        <br />
        <div class="columns">
          <div class="column">
            <div class="contentNO">
              <h3 class="title is-5">Invites available 🎁 ({{ availableInvites.length }})</h3>
              <ul>
                <li v-for="invite in availableInvites" :key="invite.id">
                  <div
                    class="box"
                    style="max-width: 400px; margin-bottom: 5px; display: flex; justify-content: space-between"
                  >
                    <router-link :to="'/invite/' + invite.inviteKey" style="font-size: 28px">
                      {{ invite.inviteKey }}
                    </router-link>
                    <b-button @click="copyKey(invite.inviteKey)" class="is-small is-dark">
                      <i class="far fa-copy"></i>
                      <span> Copy invite </span>
                    </b-button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="column" v-if="usedInvites.length">
            <h3 class="title is-5">Invited users 👶 ({{ acceptedInvites }})</h3>
            <ul>
              <li v-for="invite in usedInvites" :key="invite.id">
                <router-link :to="'/u/' + invite.usedBy + ''"> ✅ @{{ invite.usedBy }} </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--  
      {{ invites }} == invites -->
    </div>
  </div>
</template>

<script>
import settingsTabs from "@/components/nav/settingsTabs.vue";

export default {
  props: ["user"],
  components: {
    settingsTabs,
  },
  data() {
    return {
      invites: [],
    };
  },
  async mounted() {
    try {
      //  const response = await fetch(`/users/${this.user.id}/invites`);
      //const data = await response.json();
      var data = await window.API.me.getUserInvites();

      this.invites = data;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async copyKey(k) {
      // use navigator.share if available
      var text = `${window.location.origin}/invite/${k}`;
      if (navigator.share) {
        try {
          await navigator.share({
            title: "OnlyBots Invite Link",
            text: text,
            url: text,
          });
          this.$buefy.toast.open({
            message: "Shared successfully",
            type: "is-dark",
          });
        } catch (error) {
          console.error("Error sharing:", error);
          this.$buefy.toast.open({
            message: "Failed to share",
            type: "is-danger",
          });
        }
      } else {
        await navigator.clipboard.writeText(text);
        this.$buefy.toast.open({
          message: "Copied to clipboard",
          type: "is-dark",
        });
      }
    },
  },
  computed: {
    availableInvites() {
      return this.invites.filter((invite) => !invite.used);
    },
    usedInvites() {
      return this.invites.filter((invite) => invite.used);
    },
    acceptedInvites() {
      return this.usedInvites.length;
    },
  },
};
</script>
